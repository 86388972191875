const axios = require('axios')
const host = 'https://guicloud.thinkerx.com'

function randomString(len) {
  len = len || 32
  var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  var maxPos = $chars.length
  var pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}
function todayDate() {
  var date = new Date() //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + '年'
  var M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '_'
  var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ''
  var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  var m =
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ''
  ;+''
  var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return M + D
}

function uploadVideoToOss(file, complateFunc, progressFunc) {
  let upToken = {
    accessid: '',
    host: '',
    policy: '',
    signature: '',
    expire: 0,
    callback: '',
    dir: '',
  }
  axios({
    url: `${host}/guigui/oss/getToken`,
    method: 'POST',
    data: {
      dir: 'cloudPictures/',
    },
  }).then((res) => {
    upToken = res.data
    var form = new FormData()
    var rand = randomString(10)
    var houzhui = file.name.split('.')
    var key = todayDate() + '/' + rand + '.' + houzhui[houzhui.length - 1]
    form.append('name', file.name)
    form.append('key', upToken.dir + key)
    form.append('OSSAccessKeyId', upToken.accessid)
    form.append('signature', upToken.signature)
    form.append('policy', upToken.policy)
    form.append('file', file)
    axios
      .post(upToken.host, form, {
        onUploadProgress: (e) => {
          let progressRate = (e.loaded / e.total) * 100
          progressFunc(progressRate)
        },
      })
      .then(() => {
        complateFunc(upToken.host + '/' + upToken.dir + key)
      })
  })
}

export function uploadVideoToOssPromise(
  file,
  progressFunc,
  dir = 'modelVideo/'
) {
  let upToken = {
    accessid: '',
    host: '',
    policy: '',
    signature: '',
    expire: 0,
    callback: '',
    dir: '',
  }
  let tokenPromise = new Promise((resolve, reject) => {
    axios.post(`${host}/guigui/oss/getToken`, { dir: dir }).then(
      (res) => {
        let upToken = res.data
        var form = new FormData()
        var rand = randomString(10)
        var houzhui = file.name.split('.')
        console.log('====文件', file)
        var key =
          dir == 'modelVideo/'
            ? todayDate() + '/' + rand + '.' + houzhui[houzhui.length - 1]
            : file.name
        form.append('name', file.name)
        form.append('key', upToken.dir + key)
        form.append('OSSAccessKeyId', upToken.accessid)
        form.append('signature', upToken.signature)
        form.append('policy', upToken.policy)
        form.append('file', file)

        axios
          .post(upToken.host, form, {
            onUploadProgress: (e) => {
              let progressRate = (e.loaded / e.total) * 100
              progressFunc(progressRate)
            },
          })
          .then(
            () => {
              resolve(upToken.host + '/' + upToken.dir + key)
            },
            (err) => {
              reject(err)
            }
          )
      },
      (err) => {
        reject(err)
      }
    )
  })
  return tokenPromise
}

export function uploadfileToOssPromise(
  file,
  progressFunc,
  dir = 'quotation_sheet/'
) {
  let upToken = {
    accessid: '',
    host: '',
    policy: '',
    signature: '',
    expire: 0,
    callback: '',
    dir: '',
  }
  let tokenPromise = new Promise((resolve, reject) => {
    axios.post(`${host}/guigui/oss/getToken`, { dir: dir }).then(
      (res) => {
        let upToken = res.data
        var form = new FormData()
        var rand = randomString(10)
        var houzhui = file.name.split('.')
        console.log('====文件', file)
        var key =
          dir == 'quotation_sheet/'
            ? todayDate() + '/' + rand + '.' + houzhui[houzhui.length - 1]
            : file.name
        form.append('name', file.name)
        form.append('key', upToken.dir + key)
        form.append('OSSAccessKeyId', upToken.accessid)
        form.append('signature', upToken.signature)
        form.append('policy', upToken.policy)
        form.append('file', file)

        axios
          .post(upToken.host, form, {
            onUploadProgress: (e) => {
              let progressRate = (e.loaded / e.total) * 100
              progressFunc(progressRate)
            },
          })
          .then(
            () => {
              resolve(upToken.host + '/' + upToken.dir + key)
            },
            (err) => {
              reject(err)
            }
          )
      },
      (err) => {
        reject(err)
      }
    )
  })
  return tokenPromise
}
export default {
  uploadVideoToOss: uploadVideoToOss,
  uploadVideoToOssPromise: uploadVideoToOssPromise,
  uploadfileToOssPromise: uploadfileToOssPromise,
}
