<template>
  <div class="factory_add">
    <div class="basicinfo">
      <div class="bsicinfo">
        <div class="left"></div>
        <div class="baici">基础信息</div>
      </div>
      <div class="form">
        <el-form
          label-width="131px"
          class="demo-ruleForm"
          :model="bacisiform"
          :rules="rules"
          ref="bacisiform"
        >
          <el-form-item label="工厂名称" prop="name" class="labelsize">
            <el-input v-model="bacisiform.name" class="bacsiinput" />
          </el-form-item>
          <el-form-item label=" 联系人" prop="contacts" class="labelsize">
            <el-input v-model="bacisiform.contacts" class="bacsiinput" />
          </el-form-item>
          <el-form-item label=" 联系方式" prop="information" class="labelsize">
            <el-input v-model="bacisiform.information" class="bacsiinput" />
          </el-form-item>
          <el-form-item label=" 价格" prop="price" class="labelsize">
            <el-input v-model="bacisiform.price" class="bacsiinput" />
          </el-form-item>
          <el-form-item label=" 评分" prop="score" class="labelsize">
            <el-input v-model="bacisiform.score" class="bacsiinput" />
          </el-form-item>
          <el-form-item label=" 工厂封面" prop="cover" class="labelsize">
            <div v-if="cimgList.length == 0">
              <span class="clickupfile" @click="onclickImg('cover')"
                >上传图片</span
              >
              <div class="imgdes">支持上传jpg、jpeg格式，大小不超过10M</div>
            </div>
            <input
              type="file"
              accept="image/*"
              @change="getFile"
              style="display: none"
              ref="coverfile"
            />
            <div class="show_Img scoll">
              <div v-for="(item, index) of cimgList" :key="index" class="test">
                <img
                  :src="item.url ? item.url : item"
                  alt=""
                  class="unloadimg"
                />
                <span class="preview" @click="deletimg(index)">
                  <i
                    color="rgba(220, 220, 220, 1)"
                    class="el-icon-circle-close"
                  ></i>
                </span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label=" 地区选择" prop="region" class="labelsize">
            <el-cascader
              v-model="bacisiform.region"
              :options="options"
              @change="handleChange"
            />
          </el-form-item>
          <el-form-item label=" 详细地址" prop="address" class="labelsize">
            <el-input
              resize="none"
              type="textarea"
              v-model="bacisiform.address"
              class="bacsiaddres"
            />
          </el-form-item>
          <el-form-item label=" 营业执照" prop="port" class="labelsize">
            <div v-if="imglist.length == 0">
              <span class="clickupfile" @click="onclickImg">上传图片</span>
              <div class="imgdes">支持上传jpg、jpeg格式，大小不超过10M</div>
            </div>
            <input
              type="file"
              accept="image/*"
              @change="getopenFile"
              style="display: none"
              ref="file"
            />
            <div class="show_Img scoll">
              <div v-for="(item, index) of imglist" :key="index" class="test">
                <img
                  :src="item.url ? item.url : item"
                  alt=""
                  class="unloadimg"
                />
                <span class="preview" @click="opdeletimg(index)">
                  <i
                    color="rgba(220, 220, 220, 1)"
                    class="el-icon-circle-close"
                  ></i>
                </span>
              </div>
            </div>
            <!-- 图片预览 -->
            <el-dialog v-model="dialogTableVisible" width="30%">
              <img :src="imgurl" alt="" class="Preview" />
            </el-dialog>
          </el-form-item>
          <el-form-item label="是否认证云工厂" prop="isFact">
            <el-switch
              v-model="bacisiform.isFact"
              inline-prompt
              active-text="是"
              inactive-text="否"
            />
          </el-form-item>
          <el-form-item label="公司税号" prop="tax_code" class="labelsize">
            <el-input v-model="bacisiform.tax_code" class="bacsiinput" />
          </el-form-item>
          <el-form-item label=" 工厂简介" prop="introduction" class="labelsize">
            <div style="border: 1px solid #ccc">
              <Editor
                style="z-index: 1000"
                @itemclick="itemclick"
                :tel="addoredit"
              />
              <!-- <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style="height: 500px; overflow-y: hidden"
                v-model="html"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
              /> -->
            </div>
          </el-form-item>
          <el-form-item label=" 案例图片" prop="info_picture" class="labelsize">
            <div class="show_Img scoll">
              <div v-for="(item, index) of infoList" :key="index" class="test">
                <img
                  :src="item.url ? item.url : item"
                  alt=""
                  class="unloadimg"
                />
                <span class="preview" @click="infodeleteimg(index)">
                  <i
                    color="rgba(220, 220, 220, 1)"
                    class="el-icon-circle-close"
                  ></i>
                </span>
              </div>
            </div>
            <div v-if="infoList.length < 8">
              <span class="clickupfile" @click="onclickImg('info_picture')"
                >上传图片</span
              >
              <div class="imgdes">
                支持上传jpg、jpeg格式，大小不超过10M,最多上传8张
              </div>
            </div>
            <input
              type="file"
              accept="image/*"
              @change="getopenFileInfo"
              style="display: none"
              ref="infoFlie"
            />
            <!-- 图片预览 -->
            <el-dialog v-model="dialogTableVisible" width="30%">
              <img :src="imgurl" alt="" class="Preview" />
            </el-dialog>
          </el-form-item>
          <!-- 经销商报价表 -->
          <el-form-item label="经销商报价表上传">
            <div class="show_Img scoll">
              <div
                v-for="(item, index) of pricePicList"
                :key="index"
                class="test"
              >
                <img
                  :src="item.url ? item.url : item"
                  alt=""
                  class="unloadimg"
                />
                <span class="preview" @click="pricedeleteimg(index)">
                  <i
                    color="rgba(220, 220, 220, 1)"
                    class="el-icon-circle-close"
                  ></i>
                </span>
              </div>
            </div>
            <div v-if="pricePicList.length == 0">
              <span class="clickupfile" @click="onclickImg('price_picture')"
                >上传图片</span
              >
              <div class="imgdes">支持上传jpg、jpeg格式，大小不超过10M</div>
            </div>
            <input
              type="file"
              accept="image/*"
              @change="getPriceFile"
              style="display: none"
              ref="priceFlie"
            />
            <!-- 图片预览 -->
            <el-dialog v-model="dialogTableVisible" width="30%">
              <img :src="imgurl" alt="" class="Preview" />
            </el-dialog>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="basicinfo">
      <div class="bsicinfo">
        <div class="left"></div>
        <div class="baici">生产信息</div>
      </div>
      <div class="form">
        <el-form
          label-width="131px"
          class="demo-ruleForm"
          :model="productionfrom"
          :rules="rules"
        >
          <el-form-item label="厂房面积(㎡)" prop="area" class="labelsize">
            <el-input v-model="productionfrom.area" class="bacsiinput" />
          </el-form-item>
          <el-form-item label=" 员工人数" prop="peoplenumber" class="labelsize">
            <el-input
              v-model="productionfrom.peoplenumber"
              class="bacsiinput"
            />
          </el-form-item>
          <el-form-item
            label=" 加工设备信息"
            prop="equipment"
            class="labelsize"
          >
            <el-input v-model="productionfrom.equipment" class="bacsiinput" />
          </el-form-item>
          <el-form-item label=" 每月生产量(㎡)" prop="output" class="labelsize">
            <el-input v-model="productionfrom.output" class="bacsiinput" />
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="btnwraper">
      <button class="cancel" @click="cancelSaveFactory('bacisiform')">
        取消
      </button>
      <button class="preservation" @click="saveAddFactory('bacisiform')">
        保存
      </button>
    </div>
  </div>
</template>

<script>
import Editor from '@/components/editor.vue'
import cityInfoList from '@/utils/areaFact'
import upload from '@/utils/uploadOss'

// import '@wangeditor/editor/dist/css/style.css'
// import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
  components: { Editor },
  data() {
    return {
      from: null,
      value: [],
      options: cityInfoList,
      addoredit: 'add', //判断编辑还是新增
      id: '', //编辑的id
      imgurl: '', //预览图片地址
      dialogTableVisible: false,
      introduction: '',
      bacisiform: {
        isFact: false,
        tax_code: '',
        name: '',
        contacts: '',
        information: '',
        region: [],
        address: '',
        introduction: '',
        price: '',
        score: '',
      },
      productionfrom: {
        area: '', //厂房面积
        peoplenumber: '', //员工人数
        equipment: '', //加工设备信息
        output: '', //每月生产量
      },
      pricePicList: [],
      cimgList: [],
      imglist: [],
      infoList: [],
      province: '', //省
      city: '', //市
      district: '', //区
      rules: {
        name: [{ required: true, message: '请输入工厂名称', trigger: 'blur' }],
        contacts: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        information: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
        ],
        // cover: [{ required: true, message: '请选择工厂封面', trigger: 'blur' }],
        region: [{ required: true, message: '请选择地区', trigger: 'change' }],
        address: [
          { required: true, message: '请填写详细地址', trigger: 'blur' },
        ],
        price: [{ required: true, message: '请输入价格', trigger: 'blur' }],
        score: [{ required: true, message: '请输入评分', trigger: 'blur' }],
        isFact: [{ required: true, message: '请选择认证', trigger: 'change' }],
        tax_code: [
          { required: true, message: '请输入公司税号', trigger: 'blur' },
        ],
      },
    }
  },
  created() {
    console.log('this.$route', this.$route.query)
    if (this.$route.query && this.$route.query.type == 'edit') {
      this.getReviewList()
    }
  },
  methods: {
    // 编辑--加载页面回显
    getReviewList() {
      this.$request.getReviewList({ id: this.$route.query.id }, (res) => {
        console.log('回显res', res)
        this.province = res.data.province
        this.city = res.data.city
        this.district = res.data.district
        this.bacisiform.name = res.data.fact_name
        this.bacisiform.contacts = res.data.name
        this.bacisiform.information = res.data.phone
        this.bacisiform.price = res.data.price
        this.bacisiform.score = res.data.score
        this.cimgList = [res.data.picture]
        this.infoList = res.data.info_picture == '' ? [] : res.data.info_picture
        this.bacisiform.region = [this.province, this.city, this.district]
        this.bacisiform.address = res.data.address
        this.imglist = res.data.license == '' ? [] : [res.data.license]
        this.addoredit = res.data.info
        this.productionfrom.area = res.data.fact_area
        this.productionfrom.peoplenumber = res.data.population
        this.introduction = res.data.info
        this.productionfrom.equipment = res.data.device_info
        this.productionfrom.output = res.data.output
        this.bacisiform.tax_code = res.data.tax_code
        this.bacisiform.isFact = res.data.certification ? true : false
        this.pricePicList = [res.data.quotation_table]
      })
    },
    itemclick(e) {
      console.log('e.html', e.html)
      this.introduction = e.html
    },
    // 删除图片
    deletimg(index) {
      this.cimgList.splice(index, 1)
    },
    opdeletimg(index) {
      this.imglist.splice(index, 1)
    },
    infodeleteimg(index) {
      this.infoList.splice(index, 1)
    },
    pricedeleteimg(index) {
      this.pricePicList.splice(index, 1)
    },
    // getfile
    getFile(e) {
      let file = e.target.files[0]
      upload.uploadVideoToOss(
        file,
        (res) => {
          console.log(res)
          this.cimgList.push(res)
          this.change = true
        },
        () => {
          e.target.value = ''
        }
      )
    },
    getopenFile(e) {
      let file = e.target.files[0]
      upload.uploadVideoToOss(
        file,
        (res) => {
          console.log(res)
          this.imglist.push(res)
          this.change = true
        },
        () => {
          e.target.value = ''
        }
      )
    },
    getopenFileInfo(e) {
      let file = e.target.files[0]
      upload.uploadVideoToOss(
        file,
        (res) => {
          console.log(res)
          this.infoList.push(res)
          this.change = true
        },
        () => {
          e.target.value = ''
        }
      )
    },
    getPriceFile(e) {
      let file = e.target.files[0]
      upload.uploadVideoToOss(
        file,
        (res) => {
          console.log(res)
          this.pricePicList.push(res)
          this.change = true
        },
        () => {
          e.target.value = ''
        }
      )
    },
    // 选择地址
    handleChange(value) {
      this.province = value[0]
      this.city = value[1]
      this.district = value[2]
      console.log(this.city)
    },
    // 选择图片
    onclickImg(type) {
      if (type == 'cover') {
        this.$refs.coverfile.click()
      } else if (type == 'info_picture') {
        this.$refs.infoFlie.click()
      } else if (type == 'price_picture') {
        this.$refs.priceFlie.click()
      } else {
        this.$refs.file.click()
      }
    },
    // 底部取消
    cancelSaveFactory(formName) {
      console.log('取消')
      this.$refs[formName].resetFields()
      this.$router.push({
        path: 'cloud-factory-info',
      })
    },
    // 底部保存
    saveAddFactory(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let payload = {}
          if (this.$route.query && this.$route.query.type == 'edit') {
            payload = {
              id: Number(this.$route.query.id),
              fact_name: this.bacisiform.name,
              name: this.bacisiform.contacts,
              phone: this.bacisiform.information,
              province: this.province,
              city: this.city,
              district: this.district,
              picture: this.cimgList[0], //工厂封面
              license: this.imglist[0], //营业执照
              info_picture: this.infoList,
              address: this.bacisiform.address,
              info: this.introduction,
              status: 0,
              uid: this.$store.state.userInfo.id,
              price: this.bacisiform.price,
              score: this.bacisiform.score,
              image: this.cimgList[0],
              tax_code: this.bacisiform.tax_code,
              certification: this.bacisiform.isFact ? 1 : 0,
              quotation_table: this.pricePicList[0],
              ...this.productionfrom,
            }
          } else {
            payload = {
              fact_name: this.bacisiform.name,
              name: this.bacisiform.contacts,
              phone: this.bacisiform.information,
              province: this.province,
              city: this.city,
              district: this.district,
              picture: this.cimgList[0], //工厂封面
              license: this.imglist[0], //营业执照
              info_picture: this.infoList,
              address: this.bacisiform.address,
              info: this.introduction,
              status: 0,
              uid: this.$store.state.userInfo.id,
              price: this.bacisiform.price,
              score: this.bacisiform.score,
              image: this.cimgList[0],
              tax_code: this.bacisiform.tax_code,
              certification: this.bacisiform.isFact ? 1 : 0,
              quotation_table: this.pricePicList[0],
              ...this.productionfrom,
            }
          }
          this.$request.getInsertCloudFact(payload, (res) => {
            console.log('新增成功', res)
            if (res.status == 1) {
              this.$message.success('新增成功')
              this.$router.push({
                path: 'cloud-factory-info',
              })
            } else {
              this.$message.error(res.msg)
            }
          })
        } else {
          this.$message.error('请填写信息')
          return false
        }
      })
    },
    // 底部保存创建下一条
    saveNextOneAdd() {
      console.log('保存创建下一条')
    },
  },
}
</script>

<style scoped lang="less">
.factory_add {
  background-color: #fff;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  .bsicinfo {
    display: flex;
    background: rgba(220, 220, 220, 1);
    height: 40px;
    padding: 8px 0;
    padding-left: 16px;
    .left {
      width: 4px;
      height: 24px;
      background: rgba(100, 118, 255, 1);
      margin-right: 8px;
    }
    .baici {
      color: rgba(0, 0, 0, 0.9);
      font-size: 16px;
      font-weight: 400;
    }
  }
  .bacsiinput {
    width: 280px;
    height: 32px;
  }
  .form {
    margin-top: 24px;
    padding-left: 108px;
    .bacsiaddres {
      width: 280px;
      height: 56px;
      border-radius: 3px;
    }
    .labelsize {
      margin-bottom: 24px;
    }
  }
  .clickupfile {
    display: inline-block;
    width: 100px;
    height: 30px;
    color: rgba(0, 0, 0, 0.9);
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
  }
  .show_Img {
    // width:500px;
    overflow-x: scroll;
    overflow: auto;
    overflow-y: hidden;
    display: flex;
    // margin-top: 20px;
  }
  .unloadimg {
    width: 77px;
    height: 77px;
    // margin: 10px;
  }
  button {
    // width: 60px;
    height: 32px;
    background: #ffffff;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    border: 1px solid #dcdcdc;
    margin-right: 8px;
    font-size: 14px;
    padding: 0 10px;
  }
  .preservation {
    background: rgba(100, 118, 255, 1);
    color: rgba(255, 255, 255, 0.9);
  }
  .btnwraper {
    text-align: center;
    button {
      cursor: pointer;
    }
  }
}
.Preview {
  width: 100%;
  height: 100%;
}
.test {
  padding: 8px;
  position: relative;
  .el-icon-circle-close {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.imgdes {
  display: block;
  width: 240px;
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 20px;
}
</style>
